@tailwind base;
@tailwind components;

body {
  @apply text-cs-gray-700;
  @apply text-base;
}

@import "loader";

.cs__banner a {
  @apply mr-1 text-white underline;
}

.tooltip-custom-theme.__react_component_tooltip.show {
  opacity: 1;
  //@apply shadow-md;
  @apply shadow-cs-tooltip;
  @apply rounded-cs-6;
  @apply border border-cs-gray-200;
}

.tooltip-custom-theme.__react_component_tooltip.show.place-right::after {
  //opacity: 1;
  @apply border border-cs-gray-200;
}

@tailwind utilities;
